import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import StylesNewsDetailsServiceInterface
  from '~/src/pages/news/[slug]/details/styles/StylesNewsDetailsServiceInterface'
import getNewsDetailsTemplateService
  from '~/src/pages/news/[slug]/details/styles/GetNewsDetailsTemplateService'

const useGetNewsDetailsTemplate = (): StylesNewsDetailsServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('newsDetails')

  useLayout('newsDetails')

  return getNewsDetailsTemplateService(pageStyle, muiTheme)
}

export default useGetNewsDetailsTemplate
