import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesNewsDetailsServiceInterface
  from '~/src/pages/news/[slug]/details/styles/StylesNewsDetailsServiceInterface'
import StylesNewsDetailsServiceStyleTwo
  from '~/src/pages/news/[slug]/details/styles/StylesNewsDetailsServiceStyleTwo'
import StylesNewsDetailsServiceStyleOne
  from '~/src/pages/news/[slug]/details/styles/StylesNewsDetailsServiceStyleOne'
import StylesNewsDetailsServiceStyleThree
  from '~/src/pages/news/[slug]/details/styles/StylesNewsDetailsServiceStyleThree'

const getNewsDetailsTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesNewsDetailsServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesNewsDetailsServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesNewsDetailsServiceStyleThree(muiTheme)
    default:
      return new StylesNewsDetailsServiceStyleOne(muiTheme)
  }
}

export default getNewsDetailsTemplateService
