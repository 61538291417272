import Image from '~/src/components/mui-wrappers/Image'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

interface Props {
  src: string,
  alt: string,
}

const NewsImage = ({ src, alt }: Props) => {
  const theme = useCustomMuiTheme()

  return (
    <Image
      src={src}
      alt={alt}
      sx={{
        width: '33%',
        height: '100%',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          aspectRatio: '2 / 1',
          objectFit: 'cover'
        }
      }}
    />
  )
}

export default NewsImage
