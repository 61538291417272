import Title from '~/src/components/mui-wrappers/Title'
import { Box, Typography } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  content: string,
}

const NewsDescription = ({ content }: Props) => {
  const { t } = useTranslations()

  return (
    <Box>
      <Title
        variant='h6'
        sx={{
          textDecoration: 'underline'
        }}
      >
        {t('global.about')}
      </Title>
      <Typography
        variant='body1'
        dangerouslySetInnerHTML={{
          __html: content
        }} />
    </Box>
  )
}

export default NewsDescription
