import { SxProps } from '@mui/material'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesNewsDetailsServiceInterface
  from '~/src/pages/news/[slug]/details/styles/StylesNewsDetailsServiceInterface'

export default class StylesNewsDetailsServiceStyleThree extends TemplateServiceStyleBase implements StylesNewsDetailsServiceInterface {
  public getPageSx(): SxProps {
    return {
      py: 5,
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
      [this.muiTheme.breakpoints.down('md')]: {
        gap: 2,
        px: 2,
        pt: `calc(var(--eo-safe-area-top) + ${this.muiTheme.spacing(5)})`,
        pb: 2
      }
    }
  }

  public getGoBackButtonSx(): SxProps {
    return {
      position: 'absolute',
      borderRadius: '9999px',
      minWidth: 'unset',
      p: 1.5,
      border: 'none',
      color: 'inherit',
      boxShadow: this.muiTheme.elevation,
      top: '-5px'
    }
  }

  public getLocationHeaderSx(): SxProps {
    return {
      position: 'relative'
    }
  }

  public getTitleSx(): SxProps {
    return {
      [this.muiTheme.breakpoints.down('md')]: {
        textAlign: 'center'
      }
    }
  }

  public getGridListSx(): SxProps {
    return {
      p: '0 !important'
    }
  }

  public getLoadingSpinnerSx(): SxProps {
    return {
      display: 'flex',
      justifyContent: 'center',
      mt: 20
    }
  }
}
