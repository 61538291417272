import { Box } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'

interface Props {
  title: string,
}

const NewsInfos = ({ title }: Props) => {
  return (
    <Box>
      <Title variant='h5'>{ title }</Title>
    </Box>
  )
}

export default NewsInfos

