import { Box, Container } from '@mui/material'
import NewsInfos from '~/src/components/marketplace/news/NewsInfos'
import NewsDescription from '~/src/components/marketplace/news/NewsDescription'
import NewsImage from '~/src/components/marketplace/news/NewsImage'
import { useEoValue } from '~/src/hooks/useEoState'
import { SELECTED_NEWS_STATE } from '~/src/stores/news'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { LAYOUT_STATE } from '~/src/stores/layout'
import GoBackButton from '~/src/components/navigation/GoBackButton'

const NewsFeedDetailsContainer = () => {
  const news = useEoValue(SELECTED_NEWS_STATE)
  const theme = useCustomMuiTheme()
  const { isMobile } = useEoValue(LAYOUT_STATE)

  if (!news) {
    return null
  }

  return (
    <Container maxWidth='md'>
      { !isMobile && (
        <Box sx={{
          my: 2
        }}>
          <GoBackButton />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
            gap: 2
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            maxWidth: '50%',
            [theme.breakpoints.down('md')]: {
              px: 2,
              maxWidth: 'unset'
            }
          }}
        >
          <NewsInfos title={news.title} />
          <NewsDescription content={news.content} />
        </Box>
        <NewsImage
          src={news.image}
          alt={news.title}
        />
      </Box>
    </Container>
  )
}

export default NewsFeedDetailsContainer

