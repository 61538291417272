import { Box } from '@mui/material'
import NewsInfos from '~/src/components/marketplace/news/NewsInfos'
import NewsDescription from '~/src/components/marketplace/news/NewsDescription'
import NewsImage from '~/src/components/marketplace/news/NewsImage'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { NEWS_FEED_BY_ID_SELECTOR_FAMILY } from '~/src/stores/news'
import { NewsFeed } from '@eo-storefronts/eo-core'
import { useParams } from 'react-router-dom'

const NewsDetailsContainer = () => {
  const params = useParams()
  const newsFeed: NewsFeed | undefined = useEoValue(NEWS_FEED_BY_ID_SELECTOR_FAMILY(params.newsId))
  const theme = useCustomMuiTheme()

  if (!newsFeed) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column-reverse',
          gap: 2
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxWidth: '50%',
          [theme.breakpoints.down('md')]: {
            px: 2,
            maxWidth: 'unset'
          }
        }}
      >
        <NewsInfos title={newsFeed.title} />
        <NewsDescription content={newsFeed.content} />
      </Box>
      <NewsImage
        src={newsFeed.image}
        alt={newsFeed.title}
      />
    </Box>
  )
}

export default NewsDetailsContainer
