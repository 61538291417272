import { SxProps } from '@mui/material'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesNewsDetailsServiceInterface
  from '~/src/pages/news/[slug]/details/styles/StylesNewsDetailsServiceInterface'

export default class StylesNewsDetailsServiceStyleTwo extends TemplateServiceStyleBase implements StylesNewsDetailsServiceInterface {
  public getPageSx(): SxProps {
    return {}
  }

  getGoBackButtonSx(): SxProps {
    return {}
  }

  getGridListSx(): SxProps {
    return {}
  }

  getLocationHeaderSx(): SxProps {
    return {}
  }

  getTitleSx(): SxProps {
    return {}
  }

  getLoadingSpinnerSx(): SxProps {
    return {}
  }
}
