import { Box, useMediaQuery } from '@mui/material'
import NewsDetailsContainer from '~/src/components/marketplace/news/NewsDetailsContainer'
import PageHeader from '~/src/components/marketplace/utils/PageHeader'
import NewsFeedDetailsContainer from '~/src/components/news/NewsFeedDetailsContainer'
import useGetNewsDetailsTemplate from '~/src/pages/news/[slug]/details/styles/useGetNewsDetailsTemplate'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { IS_MARKETPLACE_SELECTOR } from '~/src/stores/brand'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'

const NewsDetailsPage = () => {
  const pageName: keyof typeof RoutesEnum = 'NEWS_DETAIL'
  const theme = useCustomMuiTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslations()
  const isMarketplace = useEoValue(IS_MARKETPLACE_SELECTOR)

  useGetNewsDetailsTemplate()

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5
      }}
    >
      <DocumentTitleInjector pageName={pageName}/>

      <Box sx={{
        pt: 3,
        [theme.breakpoints.down('md')]: {
          pt: 0,
          pb: 2
        }
      }}>
        {isMobile && (
          <PageHeader
            previousButton
            title={t('global.news')}
          />
        )}
        {isMarketplace && <NewsDetailsContainer />}
        {!isMarketplace && <NewsFeedDetailsContainer />}
      </Box>
    </SlideFromRight>
  )
}

export default NewsDetailsPage
